import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Box} from "@material-ui/core";
import QRCode from "react-qr-code";
import IconButton from "@material-ui/core/IconButton";

export default function QrCodeUser(props){
  const [ open, setOpen ] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <IconButton
        color="primary"
        aria-label="QrCode Usuário"
        title="QrCode Usuário"
        onClick={handleClickOpen}
      >
        {/* <EditIcon fontSize="large" /> */}
        <img
          src={"/images/qr_code.png"}
          alt="QrCode Usuário"
          style={{
            maxWidth: "38px",
            maxHeight: "36px",
            width: "auto",
            height: "auto"
          }}
        />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="motivo"
        fullWidth
      >
        <DialogTitle id="motivo">
          QrCode do funcionário: {props.nome}
        </DialogTitle>
        <DialogContent />
        <DialogActions>
          <div>
            <div style={{marginRight: "170px"}}>
              <QRCode
                size={256}
                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                value={props.cpf}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
