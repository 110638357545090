import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {Box} from "@material-ui/core";
import moment from "moment";

import {
  lighten,
  makeStyles,
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogContent from "@material-ui/core/DialogContent";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {green} from "@material-ui/core/colors";
import InputMask from "react-input-mask";
import Switch from "@material-ui/core/Switch";
import api from "services/api";
import {getToken, getUser} from "services/auth";
import Swal from "sweetalert2";
import PageLoader from "@jumbo/components/PageComponents/PageLoader";

const schema = yup
  .object({
    user: yup.string().required("Usuário é obrigatório"),
    motivo: yup.string().required("Motivo é obrigatório")
  })
  .required();

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  root: {
    margin: "25px 50px",
    marginTop: "7px",
    marginLeft: "35px",
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    "& .MuiFormHelperText-root": {
      color: "red"
    }
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 400
  },
  textFieldLarge: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: 400,
    "& p": {
      color: "red"
    }
  },
  titleCard: {
    marginTop: "30px",
    marginLeft: "25px"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  formControl: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    minWidth: 120,
    "& p": {
      color: "red"
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(4)
  },
  textSave: {
    color: "white"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref){
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: green
  }
});

export default function CreateAjuste(props){
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const headers = {Authorization: "Bearer " + getToken()};
  const [ isLoading, setIsLoading ] = useState(false);
  const userLogado = getUser();

  const motivos = props.datamotivos;
  const users = props.datausers;
  const onChangeItem = props.onChangeItem;

  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(schema)
  });

  // Constantes dos campos para criar novo usuario
  const [ idUser, sendIdUser ] = useState("");
  const [ idMotivo, sendIdMotivo ] = useState("");
  const [ is_aprovado, sendIs_aprovado ] = useState(false);
  const [ just, sendJust ] = useState("");
  const [ data, sendData ] = useState("");

  // ========================================== //

  let today = moment().format("YYYY-MM-DD") + "T00:00";

  const handleCadastrar = () => {
    handleClose();

    if (data > today) {
      Swal.fire({
        icon: "warning",
        showConfirmButton: false,
        title: "Oops... ",
        text:
          "A data do ajuste não pode ser posterior ao dia de hoje " +
          moment().format("DD/MM/YYYY")
      });
      return;
    }

    Swal.fire({
      icon: "warning",
      showConfirmButton: false,
      title: "Aguarde... ⏳",
      text: "Estamos adicionando o novo ajuste de ponto"
    });

    setIsLoading(true);
    try {
      api
        .post(
          "ponto/ajustes/new/ajustesupervisor",
          {
            user_id: idUser,
            ponto_motivo_id: idMotivo,
            is_aprovado: is_aprovado,
            id_supervisor: userLogado.id,
            obs_supervisor: just,
            data: data,
            status: 1
          },
          {headers}
        )
        .then(res => {
          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3085d6",
            title: "Uhul!",
            text: "Ajuste de ponto adicionado."
          });
          setIsLoading(false);
          onChangeItem({id: idUser, nome: just});
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos adicionar o Ajuste de ponto" + err
          }).then(function(){
            window.location.reload();
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
    onChangeItem({id: idUser, nome: just});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box align="right">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          title="Cadastrar ajuste de ponto"
        >
          <AddIcon />
        </Button>
      </Box>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {" "}
              Novo Ajuste de ponto{" "}
            </Typography>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                className={(classes.margin, classes.textSave)}
                onClick={handleSubmit(handleCadastrar)}
              >
                Salvar
              </Button>
            </ThemeProvider>
          </Toolbar>
        </AppBar>

        <DialogContent>
          {isLoading === false ? (
            <div>
              <Card className={classes.root} variant="outlined">
                <h3 className={classes.titleCard}>
                  DADOS PARA CADASTRO DO AJUSTE DE PONTO
                </h3>
                <CardContent className={classes.card}>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel>Usuário</InputLabel>

                    <Select
                      name="Usuários"
                      value={idUser}
                      label="Usuários"
                      error={errors.users ? true : false}
                      {...register("user", {
                        required: true,
                        onChange: e => sendIdUser(e.target.value),
                        shouldUnregister: true
                      })}
                    >
                      {users.map((user, index) => (
                        <MenuItem key={index} value={user.id}>
                          {" "}
                          {user.nome}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.user && (
                      <FormHelperText>Usuário é obrigatório</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl variant="outlined" className={classes.textField}>
                    <InputLabel>Motivos</InputLabel>

                    <Select
                      name="Motivos"
                      value={idMotivo}
                      label="Motivo"
                      error={errors.motivo ? true : false}
                      {...register("motivo", {
                        required: true,
                        onChange: e => sendIdMotivo(e.target.value),
                        shouldUnregister: true
                      })}
                    >
                      {motivos.map((motivo, index) => (
                        <MenuItem key={index} value={motivo.id}>
                          {" "}
                          {motivo.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.motivo && (
                      <FormHelperText>Motivo é obrigatório</FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    id="datetime-local"
                    label="Data/Hora"
                    variant="outlined"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD") + "T00:00"
                    }}
                    onChange={e => {
                      sendData(e.target.value);
                    }}
                  />
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Aprovar ajuste?</FormLabel>
                    <Switch
                      color="primary"
                      focusVisibleClassName={classes.focusVisible}
                      onChange={e => sendIs_aprovado(e.target.checked)}
                      label="Aprovar Ajuste?"
                      disableRipple
                      classes={{
                        root: classes.root,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                      }}
                    />
                  </FormControl>
                  <TextField
                    variant="outlined"
                    type="text"
                    inputProps={{maxLength: 500}}
                    multiline
                    rows={4}
                    fullWidth
                    label="Justificativa"
                    onChange={e => {
                      sendJust(e.target.value);
                    }}
                  />
                </CardContent>
              </Card>
            </div>
          ) : (
            <PageLoader />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
