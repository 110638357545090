import React from "react";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import UpdateUsuario from "./UpdateUsuario";
import DeleteUsuario from "./DeleteUsuario";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import QrCodeUser from "./QrCodeUser";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 30,
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  table: {
    minWidth: 650
  },
  check: {
    color: "green"
  },
  close: {
    color: "red"
  }
}));

export default function TableUser(props){
  const classes = useStyles();

  const dataCentroCusto = props.dataCentroCusto;
  const dataUser = props.dataUser;
  const onChangeItem = props.onChangeItem;
  const escalas = props.escalas;
  const dataRoles = props.dataRoles;

  function formataCPF(cpf){
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");

    //realizar a formatação...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.***.$3-$4");
  }

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="ajustes-pontos">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>CPF</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Nível de acesso</TableCell>
            <TableCell>Ativo</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataUser.map((user, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {user.nome}
              </TableCell>
              <TableCell component="th" scope="row">
                {formataCPF(user.cpf)}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.email ? user.email : "Não Cadastrado"}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.dscRole.nome}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.ativo ? (
                  <CheckIcon className={classes.check} />
                ) : (
                  <CloseIcon className={classes.close} />
                )}
              </TableCell>
              <TableCell align="left">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  <UpdateUsuario
                    id={user.id}
                    nome={user.nome}
                    cpf={user.cpf}
                    is_terceiro={user.is_terceiro}
                    matricula={user.matricula}
                    cargo={user.cargo}
                    rg={user.rg}
                    data_nasc={user.data_nasc}
                    sexo={user.sexo}
                    telefone={user.telefone}
                    celular={user.celular}
                    email={user.email}
                    descricao={user.descricao}
                    endereco={user.endereco}
                    bairro={user.bairro}
                    numero={user.numero}
                    cep={user.cep}
                    cidade={user.cidade}
                    estado={user.estado}
                    data_admissao={user.data_admissao}
                    centrodecustos_id={user.centrodecustos_id}
                    id_escala={user.id_escala}
                    centroCustos={dataCentroCusto}
                    onChangeItem={onChangeItem}
                    escalas={escalas}
                    dataRoles={dataRoles}
                    role={user.role_id}
                    ativo={user.ativo}
                  />
                  <DeleteUsuario
                    id={user.id}
                    nome={user.nome}
                    onChangeItem={onChangeItem}
                  />
                  <QrCodeUser cpf={user.cpf} nome={user.nome} />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
