import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import {lighten, makeStyles} from "@material-ui/core/styles";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";

import TabelaMotivo from "./TabelaMotivo";
import CreateMotivo from "./CreateMotivo";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import {getToken} from "../../../services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: 400
  }
}));

const breadcrumbs = [ {label: "Motivos", link: "/", isActive: true} ];

export default function Motivo(){
  const classes = useStyles();

  const [ isLoading, setIsLoading ] = useState(true);
  const headers = {Authorization: "Bearer " + getToken()};
  const [ dataMotivo, setdataMotivo ] = useState([]);
  const [ dataMotivoFiltered, setDataMotivoFiltered ] = useState([]);
  const [ dataFiltro, setDataFiltro ] = useState([]);

  useEffect(() => {
    getAllMotivos();
  }, []);

  const getAllMotivos = async () => {
    setIsLoading(true);
    try {
      api
        .get(`ponto/motivos`, {headers})
        .then(response => {
          setdataMotivo(response.data.data);
          setDataMotivoFiltered(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos consultar os motivos "
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(false);
  };

  function onChangeItem(item){
    getAllMotivos();
  }

  function handleSearch(search){
    setDataMotivoFiltered(dataMotivo);
    setDataFiltro([]);

    if (search == "") {
      setDataMotivoFiltered(dataMotivo);
    } else {
      dataMotivoFiltered.forEach(filter => {
        let busca = search
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        let nome = filter.descricao
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        if (!dataFiltro.includes(filter) && nome.includes(busca)) {
          dataFiltro.push(filter);
        }

        setDataMotivoFiltered(dataFiltro);
      });
    }
  }

  return (
    <PageContainer heading="Motivos" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{marginBottom: 10}}>Gerenciar Motivos</div>
          <Divider />
          <div style={{marginTop: 24}}>
            <Card className={classes.root}>
              <CardContent>
                <CreateMotivo onChangeItem={onChangeItem} />
                <br />
                <br />
                <TextField
                  autoFocus
                  autoComplete={"off"}
                  variant="outlined"
                  id="search"
                  type="text"
                  className={classes.textField}
                  placeholder="Pesquise"
                  name="search"
                  onChange={e => {
                    handleSearch(e.target.value);
                  }}
                />
                {isLoading != true ? (
                  <TabelaMotivo
                    dataMotivo={dataMotivoFiltered}
                    onChangeItem={onChangeItem}
                  />
                ) : (
                  <PageLoader />
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
