import React, {useState, useEffect} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import {lighten, makeStyles} from "@material-ui/core/styles";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";

import TableUser from "./TableUser";
import CreateUser from "./CreateUser";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import {getToken} from "../../../services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: 400
  }
}));

const breadcrumbs = [
  {
    label: "Usuários/Funcionários",
    link: "/",
    isActive: true
  }
];

export default function User(){
  const classes = useStyles();

  const [ isLoading, setIsLoading ] = useState(true);
  const headers = {Authorization: "Bearer " + getToken()};
  const [ dataRoles, setRoles ] = useState([]);
  const [ dataUser, setUser ] = useState([]);
  const [ dataCentroCusto, setCentroCusto ] = useState([]);
  const [ escalas, setEscalas ] = useState([]);
  const [ dataUserFiltered, setDataUserFiltered ] = useState([]);
  const [ dataFiltro, setDataFiltro ] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      setIsLoading(true);

      api
        .get(`ponto/usuarios`, {headers}, {password: true})
        .then(response => {
          setUser(response.data.data);
          setDataUserFiltered(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085d6",
            title: "Oops...",
            text: "Não conseguimos consultar os usuários"
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085d6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);
  };

  function onChangeItem(item){
    getAllUsers();
  }

  useEffect(() => {
    async function handleGetRoles(){
      try {
        api
          .get(`ponto/roles`, {headers})
          .then(response => {
            setRoles(response.data.data);
          })
          .catch(error => {
            Swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              title: "Oops...",
              text: "Não conseguimos consultar os dados do centro de custos"
            });
          });
      } catch (err) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          title: "Oops...",
          text: "Não conseguimos conectar na api"
        });
      }
    }
    handleGetRoles();
  }, []);

  useEffect(() => {
    async function handleCentroCustos(){
      try {
        api
          .get(`ponto/centro-custos`, {headers})
          .then(response => {
            setCentroCusto(response.data.data);
          })
          .catch(error => {
            Swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              title: "Oops...",
              text: "Não conseguimos consultar os dados do centro de custos"
            });
          });
      } catch (err) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          title: "Oops...",
          text: "Não conseguimos conectar na api"
        });
      }
    }
    handleCentroCustos();
  }, []);

  useEffect(() => {
    async function handleEscalas(){
      try {
        api
          .get(`ponto/escalas`, {headers})
          .then(response => {
            setEscalas(response.data.data);
          })
          .catch(error => {
            Swal.fire({
              icon: "error",
              confirmButtonColor: "#3085d6",
              title: "Oops...",
              text: "Não conseguimos consultar os dados da escala"
            });
          });
      } catch (err) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#3085d6",
          title: "Oops...",
          text: "Não conseguimos conectar na api"
        });
      }
    }
    handleEscalas();
  }, []);

  function handleSearch(search){
    setDataUserFiltered(dataUser);
    setDataFiltro([]);

    if (search == "") {
      setDataUserFiltered(dataUser);
    } else {
      dataUserFiltered.forEach(filter => {
        let busca = search
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        let nome = filter.nome
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        let role = filter.dscRole.nome
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        if (!dataFiltro.includes(filter)) {
          if (nome.includes(busca) || role.includes(busca)) {
            dataFiltro.push(filter);
          }

          if (filter.email != null) {
            let email = filter.email
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");

            if (email.includes(busca)) {
              dataFiltro.push(filter);
            }
          }
        }

        setDataUserFiltered(dataFiltro);
      });
    }
  }

  return (
    <PageContainer heading="Usuários/Funcionários" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{marginBottom: 10}}>Gerenciar Usuários/Funcionários</div>
          <Divider />
          <div style={{marginTop: 24}}>
            <Card className={classes.root}>
              <CardContent>
                <CreateUser
                  onChangeItem={onChangeItem}
                  dataCentroCusto={dataCentroCusto}
                  dataRoles={dataRoles}
                  escalas={escalas}
                />
                <br />
                <br />
                <TextField
                  autoFocus
                  autoComplete={"off"}
                  variant="outlined"
                  id="search"
                  type="text"
                  className={classes.textField}
                  placeholder="Buscar"
                  name="search"
                  onChange={e => {
                    handleSearch(e.target.value);
                  }}
                />
                {isLoading != true ? (
                  <TableUser
                    dataUser={dataUserFiltered}
                    dataCentroCusto={dataCentroCusto}
                    dataRoles={dataRoles}
                    escalas={escalas}
                    onChangeItem={onChangeItem}
                  />
                ) : (
                  <PageLoader />
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
