import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import {lighten, makeStyles} from "@material-ui/core/styles";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";

import CreateEscala from "./CreateEscala";
import TabelaEscalas from "./TabelaEscalas";

import PageLoader from "@jumbo/components/PageComponents/PageLoader";
import {getToken} from "../../../services/auth";
import Swal from "sweetalert2";
import api from "../../../services/api";

import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: 400
  }
}));

const breadcrumbs = [ {label: "Escalas", link: "/", isActive: true} ];

export default function Escalas(){
  const classes = useStyles();
  const headers = {Authorization: "Bearer " + getToken()};
  const [ isLoading, setIsLoading ] = useState(true);

  const [ dataDias, setDataDias ] = useState([]);
  const [ dataEscalas, setDataEscalas ] = useState([]);
  const [ dataEscalaFiltered, setDataEscalaFiltered ] = useState([]);
  const [ dataFiltro, setDataFiltro ] = useState([]);

  useEffect(() => {
    getDaysOfTheWeek();
    getAllEscalas();
  }, []);
  const getDaysOfTheWeek = async () => {
    try {
      api
        .get("ponto/dias")
        .then(response => {
          setDataDias(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não conseguimos consultar os dias "
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);
  };

  const getAllEscalas = async () => {
    try {
      api
        .get("ponto/escalas", {headers})
        .then(response => {
          setDataEscalas(response.data.data);
          setDataEscalaFiltered(response.data.data);
          setIsLoading(false);
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#3085D6",
            title: "Oops...",
            text: "Não conseguimos consultar as escalas "
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#3085D6",
        title: "Oops...",
        text: "Não conseguimos conectar na api"
      });
    }
    setIsLoading(true);
  };

  function onChangeItem(item){
    getAllEscalas();
  }

  function handleSearch(search){
    setDataEscalaFiltered(dataEscalas);
    setDataFiltro([]);

    if (search == "") {
      setDataEscalaFiltered(dataEscalas);
    } else {
      dataEscalaFiltered.forEach(filter => {
        let busca = search
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        let nome = filter.nome
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        if (!dataFiltro.includes(filter) && nome.includes(busca)) {
          dataFiltro.push(filter);
        }

        setDataEscalaFiltered(dataFiltro);
      });
    }
  }

  return (
    <PageContainer heading="Escalas" breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <div style={{marginBottom: 10}}>Gerenciar Escalas</div>
          <Divider />
          <div style={{marginTop: 24}}>
            <Card className={classes.root}>
              <CardContent>
                <CreateEscala dataDias={dataDias} onChangeItem={onChangeItem} />
                <br />
                <br />
                <TextField
                  autoFocus
                  autoComplete={"off"}
                  variant="outlined"
                  id="search"
                  type="text"
                  className={classes.textField}
                  placeholder="Pesquise"
                  name="search"
                  onChange={e => {
                    handleSearch(e.target.value);
                  }}
                />
                {isLoading !== true ? (
                  <TabelaEscalas
                    dataEscalas={dataEscalaFiltered}
                    dataDias={dataDias}
                    onChangeItem={onChangeItem}
                  />
                ) : (
                  <PageLoader />
                )}
              </CardContent>
            </Card>
          </div>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
}
